// Libraries import
import React from "react";
import styled from "styled-components";

// Styles import
import { stylesVariables } from "../../../../styles/variables";

// Image import
import closeIcon from "../../../../assets/images/DesignSystemIcons/close.svg";

export interface Props {
  isModaleOpen: boolean;
  title?: string;
  text?: string;
  textAlignMiddle?: boolean;
  children?: React.ReactNode;
  setShowModal?: React.Dispatch<React.SetStateAction<any>>;
  maxWidth?: string;
  maxHeight?: string;
  width?: string;
  height?: string;
}

const WrapperModale = styled.div<{
  styledIsModaleOpen: boolean;
}>`
  height: 100vh;
  width: 100vw;
  background: rgba(25, 27, 30, 0.32);
  display: flex;
  justify-content: center;
  align-items: center;
  display: ${(props) => (props.styledIsModaleOpen ? "auto" : "none")};
  position: fixed;
  top: 0;
  z-index: 100001;
  left: 0;
  overflow: scroll;
`;

const CloseModaleContainer = styled.div`
  width: 100%;
  text-align: right;
  > img {
    cursor: pointer;
  }
`;

const ContainerModale = styled.div<{
  styledIsTextAlignMiddle: boolean;
  maxWidth: string;
  maxHeight: string;
  width: string;
  height: string;
}>`
  max-width: ${(props) => props.maxWidth};
  max-height: ${(props) => props.maxHeight};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background: white;
  padding: 16px 24px;

  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);

  border-radius: 4px;
  overflow: scroll;

  &::-webkit-scrollbar-track {
    background: none;
    border: none;
  }
  &::-webkit-scrollbar {
    width: 0;
  }
  @media (max-width: ${stylesVariables.mediumWindow}) {
    width: 80%;
  }
  text-align: ${(props) => (props.styledIsTextAlignMiddle ? "center" : "left")};
`;
const TitleModale = styled.div`
  font-size: 18px;
  font-family: "PulpDisplay", serif;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0 1rem 0;
`;
const TextModale = styled.div`
  font-family: "DM Sans", serif;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${stylesVariables.Stablegrey};
`;

export const Modale: React.FC<Props> = ({
  isModaleOpen,
  title,
  text,
  textAlignMiddle,
  children,
  maxWidth = "480px",
  maxHeight = "none",
  width = "100%",
  height = "auto",
  setShowModal,
}) => {
  return (
    <WrapperModale styledIsModaleOpen={isModaleOpen}>
      <ContainerModale
        styledIsTextAlignMiddle={!!textAlignMiddle}
        onClick={(e) => {
          e.stopPropagation();
        }}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
        width={width}
        height={height}
      >
        {setShowModal ? (
          <CloseModaleContainer>
            <img
              src={closeIcon}
              onClick={() => setShowModal(false)}
              alt="close picto"
            />
          </CloseModaleContainer>
        ) : null}
        {title && <TitleModale>{title}</TitleModale>}
        {text && <TextModale>{text}</TextModale>}
        {children}
      </ContainerModale>
    </WrapperModale>
  );
};
