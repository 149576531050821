import { MouseEventHandler, ReactElement, forwardRef } from "react";
import ReactLoading from "react-loading";
import styled from "styled-components";

// Styles import
import { DSColors, stylesVariables } from "../../../styles/variables";

// Local interface declaration
export interface MainButtonProps {
  name?: string;
  shadow?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  color?: string;
  disabled?: boolean;
  isLoading?: boolean;
  id?: string;
  type?: "submit" | "button" | "reset";
  form?: string;
  size?: "big" | "medium";
  dataTestId?: string;
  fontFamily?: "DM Sans" | "PulpDisplay";
  style?: React.CSSProperties;
}

interface IButtonStyles {
  shadow: boolean;
  size?: "big" | "medium";
  fontFamily: "DM Sans" | "PulpDisplay";
}

const Button = styled.button<IButtonStyles>`
  background-color: ${(props) =>
    props.color === "white"
      ? DSColors.white
      : props.color === "lightOrange"
      ? DSColors.SGLight04
      : props.color === "blue"
      ? DSColors.Mint
      : props.color === "black"
      ? DSColors.Black
      : props.color === "grey"
      ? stylesVariables.MainBackgroundColor
      : DSColors.Mint};
  color: ${(props) =>
    props.color === "white"
      ? DSColors.PowerRed
      : props.color === "lightOrange"
      ? DSColors.PowerRed
      : props.color === "blue"
      ? DSColors.white
      : props.color === "black"
      ? DSColors.white
      : props.color === "grey"
      ? stylesVariables.MainGrey
      : DSColors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  height: ${(props) => (props.size === "big" ? "50px" : "40px")};
  padding: 10px 30px;
  min-width: ${(props) => (props.size === "big" ? "160px" : "120px")};
  cursor: pointer;
  position: relative;
  -webkit-transition: all 0.6s ease-out;
  -moz-transition: all 0.6s ease-out;
  transition: all 0.6s ease-out;
  border: 2px solid transparent;
  > p {
    font-size: ${(props) => (props.size === "big" ? "1.1rem" : "1rem")};
    font-family: ${(props) => props.fontFamily};
    font-weight: 600;
    white-space: nowrap;
    letter-spacing: 0rem;
    @media (max-width: $smallWindow) {
      font-size: #{"min(0.938rem,4vw)"};
      letter-spacing: 0.02rem;
    }
  }
  &:hover {
    background-color: ${(props) =>
      props.color === "lightOrange"
        ? DSColors.white
        : props.color === "blue"
        ? DSColors.white
        : props.color === "grey"
        ? stylesVariables.BackgroundColorTwo
        : DSColors.white};
    color: ${(props) =>
      props.color === "lightOrange"
        ? DSColors.Mint
        : props.color === "blue"
        ? DSColors.Mint
        : props.color === "black"
        ? DSColors.OffBlack
        : DSColors.Mint};
    border: ${(props) =>
      props.color === "white"
        ? "none"
        : props.color === "lightOrange"
        ? `2px solid ${DSColors.SGLight04}`
        : props.color === "blue"
        ? `2px solid ${DSColors.Mint}`
        : props.color === `black`
        ? `2px solid ${DSColors.OffBlack}`
        : `2px solid ${DSColors.Mint}`};
  }
  &:disabled {
    background-color: ${(props) =>
      props.color === "orange"
        ? `rgba($color: ${DSColors.Mint}, $alpha: 0.3)`
        : "unset"};
  }
`;

const Loader = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/**
- None of the props are mandatory
- Default props are listed below
**/

export const MainButton = forwardRef<HTMLButtonElement, MainButtonProps>(
  (props, ref): ReactElement => {
    const {
      name = "Button",
      shadow = true,
      onClick,
      color = "orange",
      disabled = false,
      isLoading = false,
      id,
      type = "button",
      form,
      size = "medium",
      dataTestId,
      fontFamily = "DM Sans",
      style,
    } = props;

    return (
      <Button
        id={id}
        disabled={disabled}
        onClick={onClick}
        shadow={shadow}
        color={color}
        type={type}
        form={form}
        ref={ref}
        size={size}
        data-testid={dataTestId}
        fontFamily={fontFamily}
        style={style}
      >
        {isLoading && (
          <Loader>
            <ReactLoading
              type={"spin"}
              color={"#e4e3e3"}
              height={25}
              width={25}
            />
          </Loader>
        )}
        <p style={isLoading ? { color: "transparent" } : undefined}>{name}</p>
      </Button>
    );
  }
);
