import styled from "styled-components";

// Styles import
import { DSColors } from "../../../../styles/variables";

// Local interface declaration
export interface PickCardProps {
  text: string | number;
  isSelected?: boolean;
  onClick?: () => void;
  id?: string;
  name?: string;
  value?: string | number;
  required?: boolean;
  imageSrc?: string;
  imageAlt?: string;
}

const DivContainer = styled.label<{ isSelected: boolean }>`
  position: relative;
  min-height: 4rem;
  width: 9.5rem;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border: ${(props) =>
    props.isSelected
      ? `3px solid ${DSColors.Mint}`
      : `0.5px solid ${DSColors.SGLight04}`};
  cursor: pointer;
  padding: 5px 10px;
  background-color: ${(props) =>
    props.isSelected ? "white" : DSColors.SGLight05};
  transition: all 0.05s ease-in;
`;

const Span = styled.span<{ isSelected: boolean }>`
  text-align: center;
  color: ${(props) =>
    props.isSelected ? `${DSColors.Mint}` : `${DSColors.OffBlack}`};
  font-size: 1rem;
  font-family: "DM Sans";
  font-weight: 600;
  letter-spacing: 0.05rem;
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`;

const Image = styled.img`
  height: 2rem;
`;

export const PickCard = ({
  text,
  onClick,
  isSelected = false,
  id,
  name,
  value,
  required = false,
  imageSrc,
  imageAlt,
}: PickCardProps) => {
  return (
    <DivContainer isSelected={isSelected}>
      {imageSrc && <Image src={imageSrc} alt={imageAlt || "icon"} />}
      <Input
        type={"checkbox"}
        id={id}
        name={name}
        value={value}
        onChange={onClick}
        required={required}
      />
      <Span isSelected={isSelected}>{text}</Span>
    </DivContainer>
  );
};
