import { MouseEventHandler, ReactElement, forwardRef } from "react";
import styled from "styled-components";

// Styles import
import { DSColors } from "../../../styles/variables";

// Local interface declaration
export interface DotProps {
  name?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  id?: string;
  dataTestId?: string;
  color?: "blue" | "red";
  disabled?: boolean;
  href?: string;
  target?: string;
  isBold?: boolean;
  style?: React.CSSProperties;
}

const A = styled.a<{ disabled?: boolean; isBold?: boolean }>`
  color: ${(props) =>
    props.disabled
      ? DSColors.SGLight02
      : props.color === "red"
      ? DSColors.PowerRed
      : DSColors.Mint};
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  pointer-events: ${(props) => (props.disabled ? "none" : "default")};
  position: relative;
  font-family: "DM Sans";
  font-weight: ${(props) => (props.isBold ? 700 : 400)};
  font-size: 1rem;
  text-decoration-line: underline;
  line-height: 1.31rem;
  &:hover {
    color: ${(props) =>
      props.color === "red" ? DSColors.PRDark01 : DSColors.MintDark01};
  }
`;

/**
- None of the props are mandatory
- Default props are listed below
**/

export const TextLink = forwardRef<HTMLAnchorElement, DotProps>(
  (props, ref): ReactElement => {
    const {
      name = "Link",
      onClick,
      id,
      dataTestId,
      color,
      disabled,
      href,
      target,
      isBold = true,
      style,
    } = props;

    return (
      <A
        id={id}
        onClick={onClick}
        ref={ref}
        data-testid={dataTestId}
        color={color}
        disabled={disabled}
        href={href}
        target={target}
        isBold={isBold}
        style={style}
      >
        {name}
      </A>
    );
  }
);
