import styled from "styled-components";

// Styles import
import { DSColors, stylesVariables } from "../../../../styles/variables";

// Libraries import
import { useState } from "react";
import { useRef } from "react";

// Service import
import { useTriggerFunctionWhenClickOutsideOfElement } from "../../../../services/useTriggerFunctionWhenClickOutsideOfElement";

// Images import
import ChevronDown from "../../../../assets/images/chevron-down.svg";

// Local interfaces declaration
interface IOption {
  label: string | null;
  value: string | number | null;
}

export interface SelectDropdownProps {
  options: IOption[];
  id?: string;
  value?: string | number;
  isRequired?: boolean;
  onSelect: (option: IOption) => void;
}

const DivContainer = styled.div`
  width: 100%;
  max-width: 30rem;
  padding-bottom: 5px;
  position: relative;
  font-family: "DM Sans", serif;
  border-bottom: 1px solid ${stylesVariables.StableGrayLight};
`;

const InputContainer = styled.div<{ isSelectOpen: boolean }>`
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  > p {
    color: ${stylesVariables.StableGrayLight};
  }
  > img {
    height: 15px;
    width: 15px;
    transform: ${(props) => (props.isSelectOpen ? `rotate(180deg)` : `unset`)};
    transition: all 0.1s ease-in;
  }

  > input {
    all: unset;
    width: 100%;
    text-align: left;
    caret-color: transparent;
  }
`;

const DropdownContainer = styled.div<{ isSelectOpen: boolean }>`
  position: absolute;
  background-color: white;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
  box-shadow: 0 2px 4px 0 ${DSColors.OffBlack};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: ${(props) => (props.isSelectOpen ? `block` : `none`)};
`;

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  &:hover {
    background-color: ${stylesVariables.StableGrayLight03};
    li {
      color: white;
    }
  }
  &:hover:last-child {
    border-bottom-left-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
  }
  li {
    color: ${stylesVariables.Stablegrey};
  }
`;

export const SelectDropdown = ({
  options = [],
  value,
  onSelect,
  isRequired = false,
  id,
}: SelectDropdownProps) => {
  // Hooks call
  const selectRef = useRef(null);
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);

  // Events handlers
  const toggleSelect = () => {
    setIsSelectOpen((state) => !state);
  };
  const closeSelect = () => {
    setIsSelectOpen(() => false);
  };
  const onClick = (option: IOption) => {
    onSelect(option);
    toggleSelect();
  };

  // Close the dropdown when click outside of the dropdown
  useTriggerFunctionWhenClickOutsideOfElement(selectRef, closeSelect);

  /* eslint-disable */
  return (
    <DivContainer ref={selectRef}>
      <InputContainer onClick={toggleSelect} isSelectOpen={isSelectOpen}>
        <input
          id={id}
          required={isRequired}
          onKeyDown={(event) => event.preventDefault()}
          autoComplete="off"
          value={value}
          onChange={() => {}}
        />
        <img src={ChevronDown} alt="chevron-down" />
      </InputContainer>
      <DropdownContainer isSelectOpen={isSelectOpen}>
        {options.map((option, index) => {
          return (
            <Item key={index} onClick={() => onClick(option)}>
              <ul>
                <li>{option.label}</li>
              </ul>
            </Item>
          );
        })}
      </DropdownContainer>
    </DivContainer>
  );
};
