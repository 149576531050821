// Libraries import
import { useRef, useState, WheelEventHandler } from "react";
import SVG from "react-inlinesvg";
import styled from "styled-components";

// Styles import
import { DSColors } from "../../../../styles/variables";

// Service import
import { useTriggerFunctionWhenClickOutsideOfElement } from "../../../../services/useTriggerFunctionWhenClickOutsideOfElement";

//Images import
import arrowDropdown from "../../../../assets/images/DesignSystemIcons/arrowDropdown.svg";
import checkboxDropdown from "../../../../assets/images/DesignSystemIcons/checkboxDropdown.svg";
import checkboxDropdownBlue from "../../../../assets/images/DesignSystemIcons/checkboxDropdownBlue.svg";
import checkboxDropdownBlueFilled from "../../../../assets/images/DesignSystemIcons/checkboxDropdownBlueFilled.svg";
import { Tag } from "../../Tags/Tag/Tag";

//functions export
/** exported function to manage MULTIPLE selection: pass the option returned from the onSelect function, the list of selected objects and the set state function **/
export function manageDropdownMultipleSelection(
  optionSelected: IOption,
  optionsSelectedList: IOption[],
  setFunction: React.Dispatch<React.SetStateAction<IOption[]>>
): void {
  if (optionsSelectedList.some((i) => i.value === optionSelected.value)) {
    setFunction(
      optionsSelectedList.filter((i) => i.value !== optionSelected.value)
    );
  } else {
    setFunction([...optionsSelectedList, optionSelected]);
  }
}

/** exported function to manage SINGLE selection: pass the option returned from the onSelect function, the list of selected objects and the set state function **/
export function manageDropdownSingleSelection(
  optionSelected: IOption,
  setFunction: React.Dispatch<React.SetStateAction<IOption[]>>
): void {
  setFunction([optionSelected]);
}

// Local interfaces declaration
interface IOption {
  label: string | null;
  value: string | null;
  color?: string;
}

/** options, optionsSelected and onSelect are mandatory **/
export interface SelectDropdownProps {
  /** list of options shown in the dropdown **/
  options: IOption[];
  /** list of options selected by the user shown in the tag shared component - it is possible to deselect the single option shown **/
  optionsSelected: IOption[];
  /** single option selected by the user shown in the input field **/
  id?: string;
  isRequired?: boolean;
  /** function to execute when the user selects a dropdown option (feeds the list of selected options) **/
  onSelect: (option: IOption) => void;
  /** tells if multiple selections are allowed  **/
  multipleChoices?: boolean;
  /** tells if select all option is shown */
  selectAll?: boolean;
  /** tells if the options list is searchable  **/
  searchable?: boolean;
  /** tells if the component must show the selected items list as deselectables boxes (true) or as a list of strings, separated by a ',' (false) **/
  tags?: boolean;
  /** true for use outside tables - false for use in tables **/
  background?: boolean;
  /** true to show values instead of lables **/
  showValue?: boolean;
  placeholder?: string;
  fontSize?: number;
  maxHeight?: string;
}

export interface SearchBoxProps {
  searchable?: boolean;
}

export interface BoxesContainerProps {
  optionsSelected?: IOption[];
  tags?: boolean;
}

const WrapperButton = styled.div`
  position: relative;
  min-width: 110px;
`;

const InputContainer = styled.div<{
  isSelectOpen: boolean;
  isValueSelected: boolean;
  isSearchable: boolean;
  isBorder: boolean;
  isBackground: boolean;
  isRequired: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-family: "DM Sans", serif;
  font-size: 16px;
  font-weight: 500;
  border-radius: 4px;
  background-color: ${(props) =>
    props.isBackground ? `${DSColors.white}` : `none`};
  cursor: pointer;
  z-index: 1;
  height: 46px;
  padding-left: 20px;
  box-sizing: border-box;
  color: ${(props) =>
    props.isValueSelected ? `${DSColors.OffBlack}` : `${DSColors.StableGrey}`};
  border: ${(props) =>
    props.isSelectOpen && props.isBorder
      ? `1px solid ${DSColors.Mint}`
      : props.isBorder === false
      ? "1px solid rgba(255, 0, 0, 0)"
      : `1px solid ${DSColors.SGLight02}`};
  overflow: hidden;
  position: relative;

  &:hover {
    border: 1px solid ${DSColors.Mint};
    color: ${DSColors.Mint};

    path {
      fill: ${DSColors.Mint};
    }
  }

  > input {
    all: unset;
    width: 1px;
    text-align: left;
    caret-color: transparent;
    color: transparent;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const DropdownContainer = styled.ul<{
  isSelectOpen: boolean;
  maxHeight?: string;
}>`
  box-shadow: 0 20px 80px rgba(14, 31, 63, 0.07);
  border-radius: 0 0 4px 4px;
  overflow: hidden;
  display: ${(props) => (props.isSelectOpen ? `block` : `none`)};
  z-index: 2;
  max-height: ${(props) => (props.maxHeight ? props.maxHeight : `180px`)};
  overflow-y: scroll;
  position: absolute;
  background-color: ${DSColors.white};
  width: 100%;
`;
const Item = styled.li<{
  isSelectedItem: boolean;
  isMultipleChoices: boolean;
  color: string | undefined;
}>`
  display: flex;
  align-items: center;
  font-family: "DM Sans", serif;
  font-size: 16px;
  padding: 20px 20px 12px 12px;
  cursor: pointer;
  position: relative;
  width: 100%;
  color: ${(props) => (props.color ? props.color : DSColors.StableGrey)};
  display: -webkit-box;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:not(:last-child) {
    &::after {
      content: "";
      display: block;
      width: calc(100% - 24px);
      height: 1px;
      position: absolute;
      bottom: 0;
      background-color: ${DSColors.SGLight04};
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  &:first-child {
    padding-top: 24px;
    margin-top: -4px;
  }

  &:hover {
    background-color: ${DSColors.SGLight05};

    &::before {
      background-image: ${(props) =>
        props.isSelectedItem
          ? `url${checkboxDropdownBlueFilled}`
          : `url${checkboxDropdownBlue}`};
    }
  }

  &::before {
    content: "";
    background-image: ${(props) =>
      props.isSelectedItem
        ? `url(${checkboxDropdownBlueFilled})`
        : `url(${checkboxDropdown})`};
    background-repeat: no-repeat;
    background-position: center;
    width: 24px;
    height: 24px;
    margin-right: 8px;
    display: ${(props) => (props.isMultipleChoices ? `block` : `none`)};
  }
`;
const SearchBoxWrapper = styled.div<SearchBoxProps>`
  display: ${(props) => (props.searchable ? `flex` : `none`)};
  justify-content: center;
`;
const SearchBox = styled.input<SearchBoxProps>`
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  text-align: left;
  padding: 20px 20px 12px 12px;
  width: 100%;
  border-bottom: 2px solid ${DSColors.SGLight03};
  color: ${DSColors.StableGrey};
`;
const Title = styled.p<{ fontSize?: number }>`
  font-size: ${(props) => `${props.fontSize}px`};
  color: ${DSColors.StableGrey};
  line-height: initial;
`;
const SingleOptionSelected = styled.p<{
  fontSize?: number;
  showValue?: boolean;
}>`
  font-size: ${(props) => `${props.fontSize}px`};
  color: ${DSColors.OffBlack};
`;
const BoxesContainer = styled.div<BoxesContainerProps>`
  display: flex;
  justify-content: flex-start;
  gap: 3px;
  flex: 0 0 auto;
  width: calc(100% - 60px);
  overflow-x: scroll; /* set overflow-x to scroll */
  overflow-y: hidden; /* hide vertical scroll */

  ::-webkit-scrollbar {
    display: none;
  }

  ::-webkit-scrollbar-thumb {
    display: none;
  }

  scrollbar-width: none;
`;

const ArrowSvg = styled(SVG)<{ opened?: number; active?: number }>`
  position: absolute;
  right: 20px;
  width: 14px;
  height: 7px;
  display: block;
  transform: ${(props) =>
    props.opened === 1 ? "rotate(180deg)" : "rotate(0deg)"};
  transition: all 0.1s ease-in;

  path {
    fill: ${(props) =>
      props.opened === 1
        ? `${DSColors.OffBlack}`
        : props.active === 1
        ? `${DSColors.OffBlack}`
        : `${DSColors.SGLight02}`};
  }
`;

export const Dropdown = ({
  options = [],
  optionsSelected = [],
  onSelect,
  multipleChoices = false,
  selectAll = false,
  searchable = false,
  background = true,
  placeholder = "",
  tags = true,
  fontSize = 16,
  showValue = false,
  isRequired = false,
  maxHeight,
}: SelectDropdownProps) => {
  // Hooks call
  const selectRef = useRef(null);
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);
  const [optionSearched, setOptionSearched] = useState<string | null>(null);

  // Events handlers
  const handleToggleSelect = () => {
    setIsSelectOpen((state) => !state);
    setOptionSearched("");
  };
  const handleCloseSelect = () => {
    setIsSelectOpen(() => false);
    setOptionSearched("");
  };

  // Close the dropdown when click outside of the dropdown
  useTriggerFunctionWhenClickOutsideOfElement(selectRef, handleCloseSelect);

  const containerRef = useRef<HTMLDivElement>(null);
  const handleWheel: WheelEventHandler<HTMLDivElement> = (event) => {
    const delta = event.deltaX || event.deltaY;
    if (containerRef.current) {
      containerRef.current.scrollLeft += delta;
    }
  };

  return (
    <WrapperButton ref={selectRef}>
      <InputContainer
        isSearchable={!!searchable}
        isValueSelected={optionsSelected?.length > 0}
        onClick={handleToggleSelect}
        isSelectOpen={isSelectOpen}
        isBorder={background ? true : background === false && isSelectOpen}
        isBackground={!!background}
        isRequired={isRequired}
      >
        {/* Input put here but invisible, to be able to use the "required" option of the browser*/}
        {isRequired ? (
          <input
            required={isRequired}
            onKeyDown={(event) => event.preventDefault()}
            autoComplete="off"
            value={optionsSelected?.[0]?.value ?? undefined}
            onChange={() => {}}
          />
        ) : null}
        <ArrowSvg
          active={optionsSelected?.length > 0 ? 1 : 0}
          opened={isSelectOpen ? 1 : 0}
          src={arrowDropdown}
        />
        <BoxesContainer
          ref={containerRef}
          onWheel={handleWheel}
          optionsSelected={optionsSelected}
          tags={tags}
        >
          {/*if there is a value either in the options selected list or in the single option selected, the placeholder/title is not shown*/}
          <Title fontSize={fontSize} hidden={optionsSelected?.length > 0}>
            {placeholder}
          </Title>
          {/*if tags === true, the list of selected options is shown as a tag list, with possibility to deselect single option, else the options selected are shown as a string*/}
          {tags
            ? optionsSelected.map((option, index) => {
                return (
                  <Tag
                    key={index}
                    item={option}
                    onclickDeselect={() => {
                      onSelect(option);
                    }}
                  />
                );
              })
            : optionsSelected.length === 1
            ? optionsSelected.map((option, index) => {
                return (
                  <SingleOptionSelected
                    key={index}
                    showValue={showValue}
                    fontSize={fontSize}
                  >{`${
                    showValue ? option.value : option.label
                  }`}</SingleOptionSelected>
                );
              })
            : optionsSelected.map((option, index) => {
                return (
                  <SingleOptionSelected
                    key={index}
                    showValue={showValue}
                    fontSize={fontSize}
                  >{`${
                    showValue ? option.value : option.label
                  }, `}</SingleOptionSelected>
                );
              })}
        </BoxesContainer>
      </InputContainer>
      <DropdownContainer isSelectOpen={isSelectOpen} maxHeight={maxHeight}>
        {/*if the dropdown list is searchble, an input field appears where to search an option*/}
        <SearchBoxWrapper searchable={searchable}>
          <SearchBox
            placeholder={"Rechercher"}
            onChange={(input) => {
              setOptionSearched(input.target.value.toLowerCase());
            }}
            value={optionSearched || ""}
          />
        </SearchBoxWrapper>

        {/* If the select all option is enabled, display an extra box "Select All" */}
        {selectAll && multipleChoices ? (
          <Item
            isMultipleChoices={!!multipleChoices}
            color={DSColors.StableGrey}
            isSelectedItem={optionsSelected.length === options.length}
            onClick={() => {
              if (optionsSelected.length === options.length) {
                // If all items selected, deselect all
                options.forEach((option) => {
                  onSelect(option);
                });
              } else {
                // Else select all unselected items
                options.forEach((option) => {
                  const isSelected = optionsSelected.some(
                    (item) => item.value === option.value
                  );
                  if (!isSelected) {
                    onSelect(option);
                  }
                });
              }
            }}
          >
            Tout sélectionner
          </Item>
        ) : null}

        {/*the options list is shown and filtered based on the user input*/}
        {options
          .filter((elem) =>
            elem.label?.toLowerCase()?.includes(optionSearched || "")
          )
          .map((option, index) => {
            return (
              <Item
                isSelectedItem={
                  !!(
                    multipleChoices &&
                    optionsSelected?.some((item) => item.value === option.value)
                  )
                }
                isMultipleChoices={!!multipleChoices}
                key={index}
                onClick={() => {
                  onSelect(option);
                  !multipleChoices && handleCloseSelect();
                }}
                color={option?.color}
              >
                {option.label}
              </Item>
            );
          })}
      </DropdownContainer>
    </WrapperButton>
  );
};
